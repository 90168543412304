import {LotsViewModes_Enum} from "./rematch/models/currentAuctionLotsModel";


export type NextLotJSDATA_TypeDef = Partial<{
    app_env: string,
    rails_env: string,

    load_admin_dashboard?: boolean,

    limits: {
        transloadit_max_file_size_megabytes: number,
        transloadit_max_zip_file_size_megabytes: number,
        inventory_refno_min_length: number,

        name_max_length: number,
        description_max_length: number,

    },

    pusher: {
        app_id: string,
        app_key: string,
        cluster: string,
    },

    webapp_root_base_url:string,
    webapp_root_path: string,
    webapp_bidder_login_url: string,

    frontend_api_base_url:string,
    cardpointe_tokenizer_api_base_url:string,

    bidder_id?:number|null,
    bidder_token?:string|null,
    bidder_first_name?:string|null,
    bidder_last_name?:string|null,
    bidder_email?:string|null,
    bidder_phone_number?:string|null,
    bidder_notification_sms_auction_timeline_enabled?:boolean,

    default_clerk_increments: [],

    site_id:number,
    site_name:string,
    site_name_short:string,
    site_timezone_name: string,
    site_domain: string,
    site_homepage_banner: string,
    site_support_phone: string,
    site_tech_support_phone: string,
    site_logo_image_url: string,
    site_initial_catalog_lots_view_mode: LotsViewModes_Enum,
    webcast_viewer_signup_reminder_enabled: boolean,
    site_notification_sms_auction_timeline_enabled?:boolean
} & {

    current_admin: {
        id: number,
        email: string,
    },
    current_site?: {
        id: number,
        name: string,
        name_short: string,
        business_address_country_code: string,
    },

    form_authenticity_token: string,

    urls: {
        ajax_inventory_search: string,
        ajax_lots_fetch_inventory_item_status: string,
        ajax_item_media_file_create: string,
    },

    filter_webcast_round_type_ids: Array<number> | [],

    data_auction_rings: any,
    data_linked_lots_groups: any,


    transloadit: {
        params: {
            auth: {
                key: string,
                expires?: string
            },
            steps?: { [step: string]: Record<string, unknown> },
            notify_url?: string
        }
        signature: string,
        encoding_steps: any,
    },


    // via gon from SiteAdm::LotsController
    dromo_user: {
        id: string
        companyId: string
        companyName: string
    }
    dromo_settings: {
        title: string
        webhookUrl?: string
    }


    // via gon.global from DromoService::APIDataMappings
    dromo: {

        frontend_api_key: string,

        fields_keys: {
            inventory_refno: string,
            inventory_item_name: string,
            inventory_item_description: string,
            inventory_item_quantity: string,
            inventory_item_video_url: string,

            inventory_item_imgurl_strategy: string,
            inventory_item_imgurl_variants: string,
            inventory_item_imgs_urls: string,

            lot_number: string,
            lot_starting_bid_amount: string,
            lot_reserve_price: string,
            lot_buy_now_price: string,
            lot_link_name: string,
            lot_custom_sort: string,
            lot_invoice_tax_rate_code: string,
            lot_invoice_buyer_premium_not_taxable: string,
            lot_invoice_item_restricted_online_payment: string,
            lot_invoice_fee_1_description: string,
            lot_invoice_fee_1_amount: string,
            lot_invoice_fee_1_tax_rate_code: string,
            lot_invoice_fee_2_description: string,
            lot_invoice_fee_2_amount: string,
            lot_invoice_fee_2_tax_rate_code: string,

            lot_bid_max_amount: string,
            lot_bid_bidder_number: string,

            bidder_email: string,
            bidder_first_name: string,
            bidder_last_name: string,
            bidder_phone_number: string,
            bidder_alternate_phone_number: string,
            bidder_billing_company_name: string,
            bidder_billing_company_taxid: string,
            bidder_address_address_street: string,
            bidder_address_address_street2: string,
            bidder_address_city: string,
            bidder_address_region: string,
            bidder_address_postal_code: string,
            bidder_address_country_code: string,
            bidder_credit_card_exempt: string,
            bidder_bidding_globally_approved: string,
        }
        bulk_import_types_ids: {
            bidders: number,
            inventory_items: number,
            lots_with_refno: number,
            lots_no_refno: number,
            place_bids: number
        }
    }


    country_alpha2_codes: Array<string>,

    froala_key: string,

    webcast_state_is_preparing: boolean,
    auction_is_before_prebid_closed: boolean,
}>;



const NextLotJSDATA:NextLotJSDATA_TypeDef = {
    ... ((window as any).gon_NextLotJSDATA as any)?.global,
    ... ((window as any).gon_NextLotJSDATA),
};
// @ts-ignore
delete NextLotJSDATA.global;

export default NextLotJSDATA;


console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NextLotJSDATA:', NextLotJSDATA);

